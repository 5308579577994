.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 34px;

    min-width: 460px;
    min-height: 460px;

    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
}
@media (max-width: 720px) {
    .container {
        width: 100%;
        height: 100%;

        min-width: unset;
        min-height: unset;

        padding: 0;

        border-radius: 0;
    }
}

.container > div {
    margin-top: 30px;
}
.container > div:first-of-type {
    margin-top: unset !important;
}

.dropZone {
    width: 100%;
}

.headerText {
    font: var(--text-regular);
    color: var(--secondary);
    text-align: center;
}
.subText {
    margin-top: 4px;
    font: var(--text-small);
    color: var(--secondary);
    text-align: center;
}
.breedText {
    text-transform: capitalize;
}
